import { useQuery } from '@tanstack/react-query'
import { getAllPbiviz } from '../../api/latamApi'
import { useMemo } from 'react'
import Button from 'react-bootstrap/esm/Button'
import { useAuth0 } from '@auth0/auth0-react'
import OldPbivizVersionContainer from './OldPbivizVersionContainer'
import CustomTooltip from '../Tooltip/CustomTooltip'
import useAuthRedirect from '../../hooks/useAuthRedirect'

const PbivizContainer = () => {
  const { isAuthenticated } = useAuth0()
  const { handleLoginRedirect, isLoading } = useAuthRedirect()

  const { data, isLoading: loadingPbiviz } = useQuery(
    ['pbiviz'],
    getAllPbiviz,
    {
      suspense: true,
    }
  )
  const currentPbiviz = useMemo(() => {
    if (!data) return
    return data.find((item) => item.lastVersion)
  }, [data])
  const oldCurrentPbiviz = useMemo(() => {
    if (!data) return
    return data.filter((item) => !item.lastVersion)
  }, [data])

  if (loadingPbiviz) return
  return (
    <div style={{ paddingTop: '3rem' }}>
      <h3 style={{ textAlign: 'center' }}>Pbiviz</h3>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '4rem',
          marginBottom: '4rem',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: '2.5rem',
            borderRadius: '1rem',
            boxShadow: ' 0px 0px 73px -27px rgba(0,0,0,0.5)',
          }}
        >
          <img
            src="./Logo_BIMsualize.png"
            style={{ width: '200px', minHeight: '157.59px' }}
            onLoad={() => console.log('loaded')}
          ></img>
          <h4 style={{ textAlign: 'center' }}>
            Versión {currentPbiviz.version}
          </h4>
          {isAuthenticated ? (
            <a
              href={currentPbiviz.url}
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <Button>Descargar </Button>
            </a>
          ) : (
            <CustomTooltip text="Para descargar el fichero necesitas estar registrado en la aplicación.">
              <Button onClick={handleLoginRedirect} disabled={isLoading}>
                Registrarse
              </Button>
            </CustomTooltip>
          )}
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <OldPbivizVersionContainer versions={oldCurrentPbiviz} />
      </div>
    </div>
  )
}

export default PbivizContainer
