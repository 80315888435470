import { useAuth0 } from '@auth0/auth0-react'
import Button from 'react-bootstrap/esm/Button'
import CustomTooltip from '../Tooltip/CustomTooltip'
import { toast } from 'react-toastify'
import useAuthRedirect from '../../hooks/useAuthRedirect'

const PowerBiCard = ({ item }) => {
  console.log('tamos en japon')
  const { isAuthenticated } = useAuth0()
  const { handleLoginRedirect } = useAuthRedirect()

  const onClickLoginWithRedirect = () => {
    if (isAuthenticated) {
      return toast('La plantilla no está disponible')
    } else {
      handleLoginRedirect()
    }
  }
  return (
    <div className="card-container">
      <div className="img-powerbi-container">
        <img className="img-powerbi" src={item.img} />
        <h3>{item.title}</h3>
        <p>{item.description}</p>
        <CustomTooltip
          text="Para descargar la plantilla de PowerBI necesitas estar registrado en la aplicación."
          isVisible={!isAuthenticated}
        >
          <Button
            style={{ marginTop: '.5rem' }}
            onClick={onClickLoginWithRedirect}
          >
            {isAuthenticated ? 'Descargar' : 'Registrarse'}
          </Button>
        </CustomTooltip>
      </div>
    </div>
  )
}

export default PowerBiCard
