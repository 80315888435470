import axios from 'axios'

/* -------------------------------------------------------------------------- */
/*                                  PROPIEDAD                                 */
/* -------------------------------------------------------------------------- */

export const getAllSettings = async () => {
  const { data } = await axios.get('/api/v1/propiedad')
  return data
}

export const getAllSettingsByUser = async () => {
  const { data } = await axios.get(`/api/v1/propiedad/user`)
  return data
}

export const addSettings = async (body) => {
  const { data } = await axios.post('/api/v1/propiedad', body)
  return data
}

export const deleteSetting = async (id) => {
  const { data } = await axios.delete(`/api/v1/propiedad/${id}`)
  return data
}

/* -------------------------------------------------------------------------- */
/*                                    USERS                                   */
/* -------------------------------------------------------------------------- */
export const getUserByAuth0Id = async (id) => {
  const { data } = await axios.get(`/api/v1/usuario/auth0/${id}`)
  return data
}

/* -------------------------------------------------------------------------- */
/*                                  SESSIONS                                  */
/* -------------------------------------------------------------------------- */
// export const createAuthorize = async (body) => {
//   const { data } = await axios.post('/api/v1/session', body)
//   console.log(data)
//   return data
// }

// export const deleteAuthorizationById = async (id) => {
//   const { data } = await axios.delete(`/api/v1/session/${id}`)
//   return data
// }

// export const updateAuthorizationById = async ({ id, body }) => {
//   const { data } = await axios.patch(`/api/v1/session/${id}`, body)
//   return data
// }

/* -------------------------------------------------------------------------- */
/*                                AUTHORIZATION                               */
/* -------------------------------------------------------------------------- */

export const getAllAuthorizationByUser = async () => {
  const { data } = await axios.get('/api/v1/authorization')
  return data
}

export const createAuthorization = async (body) => {
  const { data } = await axios.post('/api/v1/authorization', body)
  console.log('data')
  return data
}

export const deleteAuthorizationById = async (id) => {
  const { data } = await axios.delete(`/api/v1/authorization/${id}`)
  return data
}

export const updateAuthorizationById = async ({ id, body }) => {
  const { data } = await axios.patch(`/api/v1/authorization/${id}`, body)
  return data
}

/* -------------------------------------------------------------------------- */
/*                                   PBIVIZ                                   */
/* -------------------------------------------------------------------------- */
export const getAllPbiviz = async () => {
  const { data } = await axios.get('/api/v1/pbiviz')
  console.log(data)
  return data.data.data
}

/* -------------------------------------------------------------------------- */
/*                                   STRIPE                                   */
/* -------------------------------------------------------------------------- */

export const getAllPrices = async (noFree) => {
  // axios.defaults.headers.common['Authorization'] =
  // 'Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IjI1UUR4TFhXZ1hyWHJ6TkdLSHpNMSJ9.eyJyYW5kb20vc3RyaXBlX2N1c3RvbWVyX2lkIjoiY3VzX08yTFJka2g0b2ZsSlp4IiwiaXNzIjoiaHR0cHM6Ly9sYXRhbS12aWV3ZXIudXMuYXV0aDAuY29tLyIsInN1YiI6Imdvb2dsZS1vYXV0aDJ8MTA5NjgzNzU1NDE5NzAxNTUxMTUzIiwiYXVkIjpbImh0dHBzOi8vd3d3LmJpbXN1YWxpemUuaW8iLCJodHRwczovL2xhdGFtLXZpZXdlci51cy5hdXRoMC5jb20vdXNlcmluZm8iXSwiaWF0IjoxNjg2MTM3Nzc3LCJleHAiOjE2ODYyMjQxNzcsImF6cCI6IkFWQ1ZYblRlZUVWQ2hFZmJRR08yS2lWR2FybE5PY3FpIiwic2NvcGUiOiJvcGVuaWQgcHJvZmlsZSBlbWFpbCJ9.ivuNPYC62TmS39nzDj-yzfud_HsK6OeKqGAfR8P0kRlVWlevd5LjZ2pymGZSZ4KUx2ZYT3a2k1_egZHj0fVWgqbVm6xsCh3ZOnzGFqt8vSoprIhBOTzycyMbFz2QoAO45o-tjoR5X0jhhUtOMYSnRKuUfpTQJbqpU-gbAWWwCfbDSQdOsBkXCGVQ2Izhp-WVVjA3gzIP6XytQbM9rhRpVqlTRL8uhqPFvPf8pMgolg6j4jeDCbn2k5yWyaDPf4n1AaidhcbNIMjVx_eVaCbKW07-kaJcb7g2iU0QOocC6GxyIaJxYIWgmlllp6Z2gL3LiA5PLhUHrC-GfYZF8dIUQA'
  const { data } = await axios.get('/api/v1/stripe/price')
  console.log('data', data)

  return !noFree
    ? data.data
        .sort((a, b) => a.unit_amount - b.unit_amount)
        .filter(
          (price) => price.id !== process.env.REACT_APP_STRIPE_PRICEID_TRIAL
        )
    : data.data
}

export const getUserStripe = async (email) => {
  const { data } = await axios.get(`/api/v1/stripe/customer?email=${email}`)
  return data.data
}

export const getFreeTrialSubscription = async () => {
  const { data } = await axios.get('/api/v1/stripe/subscription/freetrial')
  console.log(data)
  return data
}

export const createTrialSubscription = async () => {
  const { data } = await axios.post('/api/v1/stripe/subscription/freetrial')
  console.log(data)
  return data
}

export const getSubscriptions = async () => {
  const { data } = await axios.get('/api/v1/stripe/subscription')
  console.log(data)
  return data
}

export const cancelSubscription = async (subscriptionId) => {
  await axios.delete(
    `/api/v1/stripe/subscription?subscription_id=${subscriptionId}`
  )
  return
}

export const getInvoices = async (limit, firstInvoice, lastInvoice) => {
  const data = await axios.get('/api/v1/stripe/invoice', {
    params: {
      limit,
      starting_after: firstInvoice,
      ending_before: lastInvoice,
    },
  })
  console.log('invoices', data)
  return data?.data || []
}

/* -------------------------------------------------------------------------- */
/*                                 AUTODESK ??                                */
/* -------------------------------------------------------------------------- */
export const getProfileAutodesk = async () => {
  console.log('ESTAS PETANDO AQUI?')
  const { data } = await axios.get('/api/auth/profile')
  console.log('la datiiiitaaaa: ', data)
  return data
}

/* -------------------------------------------------------------------------- */
/*                                COUNTRIES                                   */
/* -------------------------------------------------------------------------- */

export const getCountriesValid = async () => {
  try {
    const { data } = await axios.get('/api/v1/env')
    return data.data
  } catch (error) {
    console.log('Este es tu error in 🌐', error)
  }
}

/* -------------------------------------------------------------------------- */
/*                                GEOLOCATION                                   */
/* -------------------------------------------------------------------------- */

export const getZipCodeUser = async () => {
  try {
    const { data } = await axios.get(
      'https://ipinfo.io/json?token=c63728190636e7'
    )
    return data
  } catch (error) {
    console.log('ESTE ES TU ERROR EN getZipCodeUser', error)
  }
}

export const getGeolocationUser = async () => {
  try {
    const { data } = await axios.get('https://ipapi.co/json/')
    return data.postal
  } catch (error) {
    console.log('ESTE ES TU ERROR EN getGeolocationUser', error)
  }
}
