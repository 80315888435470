import React, { useState, useEffect } from 'react'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/esm/Col'
import { features } from '../data/homePageData'
import HomeCard from '../components/Cards/HomeCard'
import ReactPlayer from 'react-player/youtube'
import NavbarLat from '../components/Navbar/Navbar'
import Footer from '../components/Footer/Footer'
import LegalFooter from '../components/Footer/LegalFooter'
import { scroller } from 'react-scroll'
import { separarCadena } from '../helpers/landing'

import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import { Pagination, Autoplay, Navigation } from 'swiper/modules'

const Home = () => {
  useEffect(() => {
    fetch('https://ipapi.co/json/').then((res) => res.json())
  }, [])

  const scrollTo = (event, target) => {
    event.preventDefault()
    scroller.scrollTo(target, {
      duration: 500,
      delay: 0,
      smooth: 'easeInOutQuart',
      // offset: -100,
    })
    return false
  }

  return (
    <>
      <NavbarLat />
      <Container fluid style={{ padding: '0' }}>
        {features.map((feature, i) => (
          <div
            key={`feature_${i}`}
            id={`feature_${i}`}
            style={{ overflow: 'hidden' }}
          >
            <div
              className="row feature-row"
              style={{
                // height: i != aboutUs.length - 1 && '90vh',
                minHeight: '100vh',
                height: 'fit-content',
                margin: ' 0',
                background: 'url(' + feature.bgimg + ')',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
              }}
            >
              <div
                className="col-12 col-md-12 col-lg-12 col-xl-12 d-flex align-items-center"
                style={{
                  background:
                    'linear-gradient(90deg, rgba(11,11,11,1) 0%, rgba(100,100,117,0.3) 56%, rgba(255,255,255,0.5) 100%)',
                  minHeight: '100vh',
                  padding: '0',
                  margin: '0',
                }}
              >
                <div className="col-12 col-lg-4 col-xl-3 d-flex align-items-center p-0">
                  <div
                    // className="feature-text-container"
                    style={{
                      // backgroundColor: 'rgba(255, 255, 255, 0.8)',
                      padding: '20px',
                      background: 'rgba(0, 0, 0, 0.5)',
                      maxWidth: '600px',
                    }}
                  >
                    <h2
                      style={{
                        fontFamily: 'Lato',
                        // padding: '1%',
                        color: 'white',
                        fontWeight: '800',
                        fontSize: '4.5rem',
                      }}
                    >
                      {feature.title}
                    </h2>
                    {/* </div> */}

                    {feature.detail && (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '1rem',
                        }}
                      >
                        <Swiper
                          slidesPerView={1}
                          direction={'vertical'}
                          spaceBetween={30}
                          centeredSlides={false}
                          navigation={true}
                          pagination={{
                            clickable: true,
                          }}
                          autoplay={{
                            delay: 15000,
                            disableOnInteraction: false,
                          }}
                          style={{
                            maxWidth: '100%',
                            maxHeight: '200px',
                          }}
                          modules={[Pagination, Autoplay, Navigation]}
                          className="mySwiper"
                        >
                          {separarCadena(feature.detail).map((parrafo, i) => (
                            <SwiperSlide>
                              <p
                                style={{
                                  // color: 'rgba(11, 12, 25, 0.8)',
                                  fontFamily: 'Sarabun',
                                  color: 'white',
                                  fontWeight: '400',
                                  fontSize: '1.7rem',
                                  lineHeight: '2.5rem',
                                  marginTop: '30px',
                                  marginRight: '30px',
                                }}
                              >
                                {parrafo}
                              </p>
                            </SwiperSlide>
                          ))}
                        </Swiper>
                      </div>
                    )}
                  </div>
                </div>
                {feature.vid && (
                  <div className="col-12 col-md-6 col-lg-7 col-xl-8 d-flex align-items-center">
                    <ReactPlayer
                      url={feature.vid}
                      width={'100%'}
                      height={'500px'}
                    />
                  </div>
                )}
                {feature.card && (
                  <div style={{ marginTop: '25px' }}>
                    {feature.card.map((card, i) => (
                      <div className="col-12 col-md-6 col-lg-4 col-xl-4 d-flex align-items-center">
                        <div
                          key={`card_${i}`}
                          id={`card_${i}`}
                          style={{ overflow: 'hidden', width: '100%' }}
                        >
                          <HomeCard data={card} />
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                {feature.img && (
                  <div className="col-6 col-md-6 col-lg-5 col-xl-5 d-flex align-items-center">
                    <img
                      src={feature.img}
                      alt="home-section"
                      style={{ width: '95%', marginLeft: '20%px' }}
                    />
                  </div>
                )}

                {i < features.length - 1 && (
                  <a
                    style={{
                      backgroundColor: 'black',
                      position: 'absolute',
                      left: '30px',
                      bottom: '70px',
                    }}
                    className="btn btn-circle btn-outline-semi-light hero-circle-button"
                    href="#scroll"
                    onClick={(event) =>
                      scrollTo(
                        event,
                        `feature_${i + 1}`
                        // index == headerOptions.length - 1 && true
                      )
                    }
                  >
                    <i
                      style={{ fontWeight: '600', color: 'white' }}
                      className="simple-icon-arrow-down"
                    />
                  </a>
                )}
              </div>
            </div>
          </div>
        ))}
      </Container>
      <Footer />
      <LegalFooter />
    </>
  )
}

export default Home
