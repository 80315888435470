import React, { useState } from 'react'
import Viewer from '../components/Viewer/ViewerContainer'
import WrapperPage from '../components/Common/WrapperPage'
import NavbarLat from '../components/Navbar/Navbar'
import SideTreeMenu from '../components/SideTreeMenu/SideTreeMenu'
import { Resizable } from 're-resizable'

const LatamViewer = () => {
  const [urn, setUrn] = useState(null)
  const [versionName, setVersionName] = useState(null)
  const [versionsArray, setVersionsArray] = useState([])

  return (
    <WrapperPage>
      <NavbarLat />
      <div style={{ display: 'flex' }} className="p-0 m-0 h-100">
        <Resizable
          defaultSize={{
            height: '100%',
            width: '25%',
          }}
          minHeight="100%"
          minWidth="25%"
          maxWidth="45%"
          style={{ padding: '0', borderRight: '1px solid rgba(0, 0, 0, 0.1)' }}
        >
          <div
            className="sidebar-tree scrollbar-container"
            style={{ padding: '0' }}
          >
            <SideTreeMenu
              versionName={(name) => setVersionName(name)}
              onIdSelected={(id) => setUrn(id)}
              versionArray={(versions) => setVersionsArray(versions)}
            />
          </div>
        </Resizable>

        <div style={{ flex: '1 1 auto' }} className="p-0">
          <Viewer
            versionArray={versionsArray}
            versionName={versionName}
            urn={urn}
            setUrn={(urn) => setUrn(urn)}
            setVersionName={(name) => setVersionName(name)}
          />
        </div>
      </div>
    </WrapperPage>
  )
}

export default LatamViewer
