import { useQuery } from '@tanstack/react-query'
import {
  getCountriesValid,
  getGeolocationUser,
  getZipCodeUser,
} from '../api/latamApi'

export const useGetCountries = () => {
  return useQuery({
    queryKey: ['counties-valid'],
    queryFn: () => getCountriesValid(),
    retry: 2,
  })
}

export const useGetCountriesAndZipCode = () => {
  const { data: dataValid } = useGetCountries()
  const { data: zipCodeUser, isLoading } = useQuery({
    queryKey: ['geolocation-user'],
    // queryFn: () => getZipCodeUser(),
    queryFn: () => getGeolocationUser(),
    enabled: !!dataValid,
    retry: 2,
  })

  return { dataValid, zipCodeUser, isLoading }
}
