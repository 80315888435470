import React from 'react'
import Card from 'react-bootstrap/Card'
import Accordion from 'react-bootstrap/Accordion'
import { CustomToggle } from '../ModalManageSettings/SingleSettingItem'
import { useAuth0 } from '@auth0/auth0-react'
import CustomTooltip from '../Tooltip/CustomTooltip'
import useAuthRedirect from '../../hooks/useAuthRedirect'

const OldPbivizVersionContainer = ({ versions }) => {
  const { isAuthenticated } = useAuth0()
  const { handleLoginRedirect } = useAuthRedirect()

  return (
    <Accordion defaultActiveKey="0">
      <Card style={{ marginBottom: '.4rem' }}>
        <Card.Header
          style={{
            display: 'flex',
            gap: '.5rem',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center', gap: '.5rem' }}>
            <CustomToggle eventKey="1" />
            <p style={{ margin: 0, fontWeight: 600 }}>
              Descargar versiones anteriores
            </p>
          </div>
        </Card.Header>
        <Accordion.Collapse
          eventKey="1"
          style={{ padding: '1rem', fontSize: '14px' }}
        >
          <>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '.75rem',
              }}
            >
              {versions.map((version) => (
                <div style={{ display: 'flex', gap: '4rem' }}>
                  <p>Versión {version.version}</p>
                  {isAuthenticated ? (
                    <a href={version.url}>
                      <p style={{ color: '#2891c2' }}>Descargar</p>
                    </a>
                  ) : (
                    <CustomTooltip text="Para descargar el fichero necesitas estar registrado en la aplicación.">
                      <a
                        onClick={handleLoginRedirect}
                        style={{ cursor: 'pointer' }}
                      >
                        <p style={{ color: '#2891c2' }}>Registrarse</p>
                      </a>
                    </CustomTooltip>
                  )}
                </div>
              ))}
            </div>
          </>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  )
}

export default OldPbivizVersionContainer
