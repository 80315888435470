import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Home from '../pages/Home'
import Recursos from '../pages/Recursos'
import Precios from '../pages/Precios'
import LatamViewer from '../pages/LatamViewer'

import { ViewerProvider } from '../context/ViewerContext'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import PaymentPage from '../pages/PaymentPage'
import RootLayout from '../layout/RootLayout'
import RegisterRequired from '../pages/RegisterRequired'
import SettingsLayout from '../layout/SettingsLayout'
import SubscriptionPage from '../pages/SubscriptionPage'

const MyProtectedComponent = withAuthenticationRequired(() => (
  <ViewerProvider>
    <LatamViewer />
  </ViewerProvider>
))

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/recursos" element={<Recursos />} />
        <Route path="/precios" element={<Precios />} />
        <Route path="/app" element={<MyProtectedComponent />} />
        <Route
          path="/payment"
          element={
            <RootLayout center={true}>
              <PaymentPage />
            </RootLayout>
          }
        />
        <Route
          path="/register-required"
          element={
            <RootLayout center={true}>
              <RegisterRequired />
            </RootLayout>
          }
        />
        <Route path="/settings" element={<SettingsLayout />}>
          <Route index path="subscription" element={<SubscriptionPage />} />
        </Route>
      </Routes>
    </Router>
  )
}

export default AppRouter
