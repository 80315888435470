import { Button } from '@mui/material'
import React from 'react'
import Spinner from 'react-bootstrap/Spinner'
import useAuthRedirect from '../hooks/useAuthRedirect'

const SimpleSpinner = () => (
  <Spinner animation="border" role="status">
    <span className="visually-hidden">Loading...</span>
  </Spinner>
)

const LoginButton = () => {
  const { handleLoginRedirect, isLoading, isAuthenticated } = useAuthRedirect()

  return (
    <>
      {!isLoading ? (
        <Button
          variant="contained"
          className="login-btn"
          style={{ marginRight: '1rem' }}
          onClick={handleLoginRedirect}
          disabled={isLoading}
        >
          {/* {!isAuthenticated ? 'Registrarse' : <SimpleSpinner />} */}
          {!isAuthenticated && 'Iniciar sesión'}
        </Button>
      ) : (
        <SimpleSpinner />
      )}
    </>
  )
}

export default LoginButton
