import Button from 'react-bootstrap/esm/Button'
import useAuthRedirect from '../hooks/useAuthRedirect'

const RegisterRequired = () => {
  const { handleLoginRedirect, isLoading } = useAuthRedirect()

  return (
    <div style={{ padding: '5rem', borderRadius: '1rem' }}>
      <div
        style={{ display: 'flex', justifyContent: 'center', margin: '3rem' }}
      >
        <img
          src={'./assets/img/logo_vimsualize.png'}
          style={{ width: '120px' }}
        />
      </div>
      <h1 style={{ textAlign: 'center', fontSize: '40px' }}>
        Se necesita registro
      </h1>
      <p
        style={{
          textAlign: 'center',
          fontSize: '1.5rem',
          marginBottom: '2rem',
        }}
      >
        Para suscribirse a alguno de los planes es necesario estar registrado en
        la aplicación
      </p>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          onClick={handleLoginRedirect}
          disabled={isLoading}
          style={{ width: 'auto', fontSize: '1.5rem', padding: '1rem 5rem' }}
        >
          Registrarse
        </Button>
      </div>
    </div>
  )
}

export default RegisterRequired
