import { useAuth0 } from '@auth0/auth0-react'
import { useGetCountriesAndZipCode } from '../hooks/useGetGeolocation'

const useAuthRedirect = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0()
  const { dataValid, zipCodeUser, isLoading } = useGetCountriesAndZipCode()

  const handleLoginRedirect = () => {
    if (!dataValid) return

    const countriesValid = dataValid.REACT_APP_LATAM_COUNTRIES
    const zipCodesValid = dataValid.REACT_APP_INCLUDE_POSTAL
    const domainsAllowed = dataValid.REACT_APP_DOMAINS_EMAILS

    loginWithRedirect({
      authorizationParams: {
        acr_values: `${countriesValid}-${zipCodesValid}-${zipCodeUser}-${domainsAllowed}`,
        // screen_hint: 'signup',
        screen_hint: 'signin',
        appState: {
          returnTo: '/logged',
        },
      },
    })
  }

  return { handleLoginRedirect, isLoading, isAuthenticated }
}

export default useAuthRedirect
